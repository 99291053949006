<div class="autocomplete-container" [ngClass]="{ 'expanded': isExpanded }">
    <div class="search-box" (click)="expandSearch()">
        <img *ngIf="showSearchIcon" src="assets/layout/icons/search.svg" alt="Search Icon" class="search-icon" />
        <input
            #searchInput
            [formControl]="searchControl"
            placeholder="{{ 'HEADER.SEARCH' | translate }}"
        />
        <button class="clear-btn" *ngIf="searchControl.value" (click)="clearSearch()">
            <img src="assets/layout/icons/close.svg" alt ='Close Icon' />
        </button>
    </div>

    <div class="expanded-content" [ngClass]="{ 'is-visible': isExpanded }">
        <div class="search-results">
            <ng-container *ngFor="let group of filteredItems">
                <div *ngIf="group.items.length > 0" class="group">
                    <div class="group-label">{{ group.name | translate }}</div>
                    <div class="group-items">
                        <div *ngFor="let item of group.items" (click)="onSelect(item)" class="item">
                            {{ item.label | translate }}
                        </div>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="hasNoResults()" class="no-results">{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}</div>
        </div>

        <div class="filter-options">
            <div class="filter-header">
                <span>{{ 'SIDENAV.FILTER_BY_MENUS' | translate }}</span>
                <button class="clear-all-btn-text" (click)="clearAllFilter()"  [disabled]="!isAnyFilterSelected">{{ 'MODULES.METRICS.CLEAR_FILTER' | translate }}</button>
            </div>
            <div class="filter-checkboxes">
                <!-- <label>
                    <input
                    type="checkbox"
                    [indeterminate]="isIndeterminate"
                    (change)="toggleSelectAll()"
                    [checked]="isSelectAllChecked"
                />  Select All
                </label> -->
                <label *ngFor="let filterCtrl of filtersArray.controls; let i = index">
                    <input type="checkbox" [formControl]="filterCtrl" (change)="updateActiveFilters()" />
                    <span *ngIf="filterOptions[i]">{{ filterOptions[i] | translate }}</span>
                </label>
                
            </div>
           
        </div>
    </div>
</div>
