import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid-community';
import { FilterModel, GridApi } from 'ag-grid-enterprise';
import { Subscription } from 'rxjs';

import { CellRendererService } from '@app/core/resources/cell-renderer.service';
import { FilterParamsService } from '@app/core/resources/filter-params.service';
import { FilterTextWhitespacesService } from '@app/core/resources/filter-text-whitespaces.service';
import { FilterTypeService } from '@app/core/resources/filter-type.service';
import { PaginationAggridFlexService } from '@app/core/resources/pagination-aggrid-flex.service';
import { SearchService } from '@app/layout/services/search.service';
import { Metrics } from '@app/slm/interface/metrics.tree';
import { ContractService } from '@app/slm/services/contract.service';
import { MetricPermissionService } from '@app/slm/services/metric-permission.service';
import { MetricsService } from '@app/slm/services/metrics.service';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
})
export class MetricsComponent implements OnInit, OnDestroy {
  @ViewChild('agGrid', { read: ElementRef }) gridElementRef!: ElementRef;

  @Output() closeClicked = new EventEmitter<boolean>();

  metricTreeData!: Metrics[];

  selected: any = [];

  businessId = '';

  isNewMetric = false;

  metricsTableData: any = [];

  headers: any = [];

  grid!: GridApi;

  gridOptions: GridOptions = {
    alwaysMultiSort: true,
    headerHeight: 40,
    groupHeaderHeight: 40,
    rowHeight: 30,
    suppressContextMenu: true,
    overlayNoRowsTemplate: '<span class="ag-overlay-no-rows-center">No records found</span>',
    defaultColDef: { resizable: true },
    autoGroupColumnDef: { minWidth: 250, resizable: true },
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [50, 100, 200],
    maxBlocksInCache: 0,
    cacheBlockSize: 50,
    serverSidePivotResultFieldSeparator: '_',
    pivotMode: false,
    rowModelType: 'serverSide',
    onFirstDataRendered(params) {
      // set auto height once grid is rendered
      params.api.setGridOption('defaultColDef', { autoHeight: true });
    },
    getMainMenuItems: () => {
      return this.getMainMenuItems();
    }, // custom main column header menu
  };

  haveFilters = true;

  isMetricViewEnabled = false;

  isCreateMetricEnabled = false;

  contractFilterObj: { [key: string]: FilterModel } = {};

  routeFilters: any = null; // For Global Seach handling

  private subscriptions: Subscription = new Subscription();

  constructor(
    private metricService: MetricsService,
    private translateService: TranslateService,
    public metricPermission: MetricPermissionService,
    private filterTextWhitespacesService: FilterTextWhitespacesService,
    private filterTypeService: FilterTypeService,
    private filterParams: FilterParamsService,
    private paginationService: PaginationAggridFlexService,
    private cellRendererService: CellRendererService,
    private contractService: ContractService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService
  ) {
    this.subscriptions.add(
      this.metricService.isViewOpen$.subscribe((isViewOpen: any) => {
        this.isMetricViewEnabled = isViewOpen || false;
      })
    );
    /**
     * adding new created metric on metric table
     * update metric count on both tree and folder
     */
    this.metricService.createdMetricData.subscribe((res: any) => {
      if (res) {
        this.isNewMetric = res.isNew;
        this.metricsTableData = [];
        this.grid.refreshServerSide();
      }
    });
  }

  openCreateMetricForm(): void {
    const node = { name: this.selected.name, id: this.selected.businessId };

    this.metricService.getSelectedNodeData(node);
    this.isCreateMetricEnabled = true;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.contractService.countOfMetric.subscribe((res: any) => {
        this.contractFilterObj = {
          contractname: {
            filter: res?.contractName,
            filterType: 'text',
            type: 'equals',
          },
        };
      })
    );
    this.getHeadersData();

    this.searchService.filters$.subscribe(filters => {
      if (filters && filters.length > 0) {
        this.routeFilters = filters; // Apply the filters
        this.refreshGridData(); // Refresh the grid with the new filters
      }
    });
  }

  refreshGridData(): void {
    if (this.grid) {
      // Force grid to re-fetch data using updated routeFilters
      this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());
      this.grid.onFilterChanged(); // Trigger the fetch process
    }
  }

  onGridReady(event: any): void {
    this.grid = event.api;
    this.grid.setFilterModel(this.contractFilterObj);

    // If route filters exist, apply them during initialization
    if (this.routeFilters) {
      const transformedFilters = this.transformRowGroupInfo({ filterConditions: [] }, this.routeFilters);

      this.grid.setFilterModel(transformedFilters.filterConditions);
    }
    this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());
  }

  getHeadersData(): void {
    this.metricService.getMetricHeaders().subscribe((res: any) => {
      this.headers = res.map((item: any) => this.headerFormulate(item));
    });
  }

  headerFormulate(header: any): any {
    const headerObj = {
      id: header.id,
      field: header.fieldName ? header.fieldName.toLowerCase() : header.field.toLowerCase(),
      minWidth: header.minWidth,
      maxWidth: header.maxWidth,
      wrapText: header.textWrapping,
      headerName: header.displayName ? header.displayName : header.headerName,
      sortable: header.sortable,
      sort: header.sortOrder ? header.sortDirection.toLowerCase() : header.sort,
      initialSortIndex: header.sortOrder ? header.sortOrder : header.initialSortIndex,
      filter: header.filterable ? this.filterTypeService.getMeFilterType(header.fieldType) : header.filter,
      filterParams: header.filterable ? this.filterParams.getMeFilterParams(header.fieldType) : header.filterParams,
      cellRenderer: (rowData: any) => {
        return this.cellRendererService.reportCellRenderer(rowData);
      },
      enableRowGroup: header.groupRowsBy,
      rowGroup: header.groupRowsBy,
      hide: header.groupRowsBy || header.hidden,
      pivot: header.groupColumnsBy,
      enableValue: header.enableValue,
      aggFunc: header.aggFunction,
      dataTypeFormatId: header.dataTypeFormatId,
    };

    return headerObj;
  }

  getMeSorted(dirtyData: any): any {
    if (dirtyData.length > 0) {
      const sort: any = [];

      for (let i = 0; i <= dirtyData.length - 1; i -= -1) {
        sort.push(`${dirtyData[i].colId},${dirtyData[i].sort}`);
      }

      return sort;
    }

    return '';
  }

  // prevent pagination updates on sorting when no records are available
  onSortChange(): void {
    if (!this.metricsTableData.length) {
      this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef, true);
    }
  }

  getMeFiltered(filterData: any, rowGroupInfo: any): any {
    const sqlQueries: any = [];

    Object.keys(filterData).forEach(key => {
      const filter = filterData[key];
      const conditions: any = [];

      const appliedFilterData: any = {
        fieldName: key,
        filterType: filter.filterType,
      };

      if (filter.operator) {
        appliedFilterData.operator = filter.operator;

        filter.conditions.forEach((item: any) => {
          const filterVal: any = {
            type: item.type,
            start: item.filterType === 'date' ? item.dateFrom : item.filter,
          };

          if (item.type === 'inRange') {
            filterVal.end = item.filterType === 'date' ? item.dateTo : item.filterTo;
          }
          conditions.push(filterVal);
        });
      } else {
        const filterVal: any = {
          type: filter.type,
          start: filter.filterType === 'date' ? filter.dateFrom : filter.filter,
        };

        if (filter.type === 'inRange') {
          filterVal.end = filter.filterType === 'date' ? filter.dateTo : filter.filterTo;
        }
        conditions.push(filterVal);
      }

      appliedFilterData.conditions = conditions;

      sqlQueries.push(appliedFilterData);
    });

    this.haveFilters = Object.values(this.grid.getFilterModel()).length > 0;

    const filterConditions = sqlQueries;
    const payload: any = filterConditions?.length ? { filterConditions } : {};

    const agParamsRequestData: any = this.transformRowGroupInfo(rowGroupInfo, payload);
    // Creating a new object and merging the properties of payload and rowgroupPayload into it
    const mergedPayload = { ...payload, ...agParamsRequestData };

    // This has been done as the key for the start and end row different from api end point and key does not serve it's purpose
    delete mergedPayload.startRow;
    delete mergedPayload.endRow;

    return mergedPayload;
  }

  // handling if filter only contains whitespace
  onFilterModified(e: any): void {
    this.filterTextWhitespacesService.textWhitespaceTrim(e, this.grid);
  }

  transformRowGroupInfo(rowGroupInfo: any, queryFilters?: any[]): any {
    // Create a mapping from fieldName to an object containing id, fieldName, and aggFunc from headers
    const fieldToIdMap = this.headers.reduce((accumulator: any, header: any) => {
      accumulator[header.field] = {
        id: header.id,
        fieldName: header.field,
        aggFunction: header.aggFunc,
        formatId: header.dataTypeFormatId,
      };

      return accumulator; // Return the accumulator object for the next iteration
    }, {});

    // Transform rowGroupCols by mapping each column to its corresponding id and fieldName
    const groupByRow = rowGroupInfo.rowGroupCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      // If mapping exists, replace the id and fieldName, otherwise, return the column unchanged
      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform valueCols by mapping each column to its corresponding id and fieldName, and override aggFunc if available
    const valueCols = rowGroupInfo.valueCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? {
            ...col,
            id: mappedField.id,
            fieldName: mappedField.fieldName,
            aggFunction: mappedField.aggFunc || col.aggFunc,
            formatId: mappedField.formatId,
          }
        : col;
    });

    // Transform pivotCols
    const pivotColumns = rowGroupInfo.pivotCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform sortModel
    const sortModel = rowGroupInfo.sortModel.map((sort: any) => {
      const mappedField = fieldToIdMap[sort.colId.toLowerCase()];

      return mappedField ? { ...sort, colId: mappedField.fieldName } : sort;
    });

    // Synchronize filter conditions with routeFilters
    // const filterConditions = this.routeFilters
    //   ? [...(rowGroupInfo.filterConditions || []), ...this.routeFilters] // Merge stored filters
    //   : rowGroupInfo.filterConditions;

    const customParameters = [
      {
        parameter: 'CONNECTOR_PARAM_NAME',
        parameterValues: ['Provider'],
      },
    ];

    // Create a new object with transformed properties
    return {
      ...rowGroupInfo,
      groupByRow,
      valueCols,
      pivotColumns,
      sortModel,
      customParameters,
      // filterConditions,
    };
  }

  getGridDataFromAPI(): any {
    return {
      getRows: (agParams: any) => {
        const params: any = {
          offset: agParams.request.startRow,
          limit: agParams.request.endRow - agParams.request.startRow,
          sort: this.getMeSorted(agParams.request.sortModel),
        };

        // updating cache to be in sync with page size
        this.grid.setGridOption('cacheBlockSize', this.grid.paginationGetPageSize());

        this.metricService
          .getMetricData(this.getMeFiltered(agParams.request.filterModel, agParams.request), params)
          .subscribe((res: any) => {
            this.metricsTableData = res.elements;
            if (!res.elements.length) {
              this.grid.showNoRowsOverlay();
            } else {
              this.grid.hideOverlay();
            }

            // calculating subheaders and its dataset
            if (res.reportColumnRoot && res.reportColumnRoot.length > 0) {
              const uniqueComplianceHeaders = new Set(this.headers.map((header: any) => header.field));

              res.reportColumnRoot.forEach((item: any) => {
                if (!uniqueComplianceHeaders.has(item.fieldName)) {
                  uniqueComplianceHeaders.add(item.fieldName);
                  this.headers.push(item);

                  // removing duplicate fields
                  const filteredHeaders = this.headers.filter(
                    (element: any) => element.field !== item.fieldName?.toLowerCase()
                  );

                  this.headers = filteredHeaders;
                }
              });
              this.headers = this.headers.map((header: any) => this.headerFormulate(header));
            }

            const tableData: any = res.elements.map((row: any) => {
              const rowDataEntry: any = {};
              let attributes: any = [];

              row.reportColumnData.forEach((colData: any) => {
                rowDataEntry[colData.fieldName.toLowerCase()] = colData.formattedValue;
                // Assign the value to fieldName by adding Value identifier to the key->fieldName
                rowDataEntry[`${colData.fieldName.toLowerCase()}Value`] = colData.value;

                // passing field identifier in attributes
                if (colData.reportAttributeDtos) {
                  const newAttr = colData.reportAttributeDtos?.map((v: any) => ({
                    ...v,
                    field: colData.fieldName.toLowerCase(),
                  }));

                  attributes = [...attributes, ...newAttr];
                }
                rowDataEntry.attributes = attributes;
              });

              return rowDataEntry;
            });

            agParams.success({
              rowData: tableData,
              rowCount: res.totalElements,
            });

            // auto-resize all columns by default
            // check with grouping and spanning
            agParams.columnApi.autoSizeAllColumns();

            // Workaround to show the actual number of rows for a given page
            this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef);
          });
      },
    };
  }

  clearGrid(): void {
    this.grid.setFilterModel(null);
  }

  close(): void {
    this.isMetricViewEnabled = false;
  }

  ngOnDestroy(): void {
    this.contractService.countOfMetric.next({});
    this.subscriptions.unsubscribe();
  }

  onCellClick(event: any): void {
    if (event.colDef.field === 'metricname') {
      this.metricService.getViewData(event.data);
      this.isMetricViewEnabled = true;
    }
  }

  // custom main column header menu Items
  getMainMenuItems(): [] {
    const customResetCol: any = [
      'pinSubMenu',
      'separator',
      {
        name: 'Reset Columns',
        action: () => {
          this.grid.autoSizeAllColumns();
          this.grid.resetColumnState();
        },
      },
    ];

    return customResetCol;
  }
}
