<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" translate>MODULES.CONTRACTS.DETAILS</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="px-4">
  <div class="row mt-2">
    <!--Contract Id -->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.CONTRACTS.CONTRACT_ID
    </div>
    <div class="col-9 col-sm-9">
      <ng-container *ngIf="isDataLoading(); else displayContractId">
        <p-skeleton></p-skeleton>
      </ng-container>
      <ng-template #displayContractId>
        {{ getDisplayValue(viewData?.contractNo) }}
      </ng-template>
    </div>

    <!--Contract name-->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.CONTRACT_NAME
    </div>
    <div class="col-9 col-sm-9">
      <ng-container *ngIf="isDataLoading(); else displayContractName">
        <p-skeleton></p-skeleton>
      </ng-container>
      <ng-template #displayContractName>
        {{ getDisplayValue(viewData?.contractName) }}
      </ng-template>
    </div>

    <!--Contract status-->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.CONTRACT_STATUS
    </div>
    <div class="col-9 col-sm-9">
      <ng-container *ngIf="isDataLoading(); else displayStatus">
        <p-skeleton></p-skeleton>
      </ng-container>
      <ng-template #displayStatus>
        <span
          [ngClass]="
            viewData?.status === 'Published'
              ? 'success-chip'
              : viewData?.status === 'Draft'
              ? 'warning-chip'
              : 'danger-chip'
          "
        >
          {{ getDisplayValue(viewData?.status) }}
        </span>
      </ng-template>
    </div>

    <!--Description-->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.DESCRIPTION
    </div>
    <div class="col-9 col-sm-9">
      <ng-container *ngIf="isDataLoading(); else displayDescription">
        <p-skeleton></p-skeleton>
      </ng-container>
      <ng-template #displayDescription>
        {{ getDisplayValue(viewData?.description) }}
      </ng-template>
    </div>

    <!--Customer-->
    <div class="col-3 col-sm-3 fw-bold" translate>MODULES.METRICS.CUSTOMER</div>
    <div class="col-9 col-sm-9">
      <ng-container *ngIf="isDataLoading(); else displayCustomer">
        <p-skeleton></p-skeleton>
      </ng-container>
      <ng-template #displayCustomer>
        {{ getDisplayValue(viewData?.customerName) }}
      </ng-template>
    </div>

    <!--Provider-->
    <div class="col-3 col-sm-3 fw-bold" translate>MODULES.METRICS.PROVIDER</div>
    <div class="col-9 col-sm-9">
      <ng-container *ngIf="isDataLoading(); else displayProvider">
        <p-skeleton></p-skeleton>
      </ng-container>
      <ng-template #displayProvider>
        {{ getDisplayValue(viewData?.providerName) }}
      </ng-template>
    </div>

    <!-- metrics count -->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.METRICS_COUNT
    </div>
    <div class="col-9 col-sm-9">
      <ng-container *ngIf="isDataLoading(); else displayMetricCount">
        <p-skeleton></p-skeleton>
      </ng-container>
      <ng-template #displayMetricCount>
        <div class="flex">
          <ng-container *ngIf="viewData?.metricCount > 0; else displayValue">
            <button
              pButton
              class="py-0 px-0 p-button-link text-left"
              label="{{ getDisplayValue(viewData?.metricCount) }}"
              (click)="redirectToMetrics()"
            ></button>
          </ng-container>
          <ng-template #displayValue>
            {{ getDisplayValue(viewData?.metricCount) }}
          </ng-template>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-container *ngIf="contractPermission.hasEditContract()">
  <div class="flex">
    <button
      pButton
      class="px-4 py-0 p-button-link"
      label="{{ 'MODULES.CONTRACTS.ADD_METRICS' | translate }}"
      (click)="isPopupVisible = true"
    ></button>
  </div>
</ng-container>

<h5 class="mt-4 px-4" translate>MODULES.VIEW_METRICS.VERSION_DETAILS</h5>
<p-divider></p-divider>

<div class="px-4">
  <div class="row mt-2">
    <!-- version -->
    <div class="col-12 col-sm-12" translate>
      <ng-container *ngIf="viewVersionList.length">
        <button
          pButton
          type="button"
          class="w-full p-button-outlined custom-btn-outline"
          (click)="op.toggle($event)"
        >
          <div class="w-100 text-center">
            <span class="pe-2"
              >{{
                selectedVersion
                  ? selectedVersion.version
                  : viewVersionList[0].version
              }}
            </span>
            <span
              [ngClass]="
                selectedVersion.status === 'Published'
                  ? 'success-chip'
                  : selectedVersion?.status === 'Draft'
                  ? 'warning-chip'
                  : 'danger-chip'
              "
            >
              {{
                selectedVersion
                  ? selectedVersion.status
                  : viewVersionList[0].status
              }}
            </span>
          </div>
          <i class="pi pi-angle-down"></i>
        </button>

        <p-overlayPanel #op [showCloseIcon]="true" styleClass="custom-overlay">
          <ng-template pTemplate>
            <!-- status filter -->
            <form [formGroup]="versionForm">
              <div class="mb-3">
                <span class="fw-bold pe-3 ps-2 align-middle" translate
                  >MODULES.VIEW_METRICS.FILTER_BY</span
                >
                <ng-container
                  formArrayName="statusFilter"
                  *ngFor="let check of statusList; let i = index"
                >
                  <p-checkbox
                    [label]="check.label"
                    [binary]="true"
                    class="align-middle px-2"
                    [labelStyleClass]="check.class"
                    [formControlName]="i"
                    (onChange)="applyStatusFilter($event, check.label)"
                  >
                  </p-checkbox>
                </ng-container>
              </div>
            </form>

            <p-table
              #paginationReset
              styleClass="p-datatable-sm"
              [value]="filteredVersion"
              selectionMode="single"
              [(selection)]="selectedVersion"
              (onRowSelect)="onClosePanel($event)"
              (onRowUnselect)="onClosePanel($event)"
              [paginator]="true"
              [rows]="10"
              [showCurrentPageReport]="true"
              currentPageReportTemplate="{{
                'MODULES.PAGINATION.SHOWING' | translate
              }} {first} {{ 'MODULES.PAGINATION.TO' | translate }} {last}
              {{ 'MODULES.PAGINATION.OF' | translate }} {{
                filteredVersion.length
              }}
              {{ 'MODULES.PAGINATION.ENTRIES' | translate }}"
              [scrollHeight]="'calc(100vh - 180px)'"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 110px" translate>
                    MODULES.VIEW_METRICS.VERSION_NAME
                  </th>
                  <th style="width: 90px" translate>MODULES.METRICS.STATUS</th>
                  <th style="width: 100px" translate>
                    MODULES.VIEW_METRICS.START_DATE
                  </th>
                  <th style="width: 100px" translate>
                    MODULES.VIEW_METRICS.END_DATE
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-version>
                <tr [pSelectableRow]="rowData">
                  <td>{{ version.version }}</td>
                  <td>
                    <span
                      [ngClass]="
                        version.status === 'Published'
                          ? 'success-chip'
                          : version.status === 'Draft'
                          ? 'warning-chip'
                          : 'danger-chip'
                      "
                    >
                      {{ version.status }}
                    </span>
                  </td>
                  <td>{{ version.startDate | date : 'yyyy-MM-dd' }}</td>
                  <td>{{ version.endDate | date : 'yyyy-MM-dd' }}</td>
                </tr>
              </ng-template>
              <!-- no records msg -->
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td class="text-center" [attr.colSpan]="4">
                    {{ 'MODULES.EMPTY_TABLE_MSG' | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-template>
        </p-overlayPanel>
      </ng-container>
      <p-skeleton *ngIf="!viewVersionList.length"></p-skeleton>
    </div>

    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.VERSION_NAME
    </div>
    <div class="col-9 col-sm-9">
      <span *ngIf="versionForm.get('versionName')?.value">
        {{ versionForm.get('versionName')?.value }}
      </span>
      <p-skeleton *ngIf="!versionForm.get('versionName')?.value"></p-skeleton>
    </div>

    <!--Start date-->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.START_DATE
    </div>
    <div class="col-9 col-sm-9">
      {{ versionForm.get('startDate')?.value | date : 'yyyy-MM-dd' }}
      <p-skeleton *ngIf="!versionForm.get('startDate')?.value"></p-skeleton>
    </div>

    <!--End date-->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.END_DATE
    </div>
    <div class="col-9 col-sm-9">
      {{ versionForm.get('endDate')?.value | date : 'yyyy-MM-dd' }}
      <p-skeleton *ngIf="!versionForm.get('endDate')?.value"></p-skeleton>
    </div>

    <!-- statement -->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.CONTRACTS.NOTES
    </div>
    <div class="col-9 col-sm-9">
      {{ versionForm.get('statement')?.value }}
      <p-skeleton *ngIf="!versionForm.get('statement')?.value"></p-skeleton>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-6 col-sm-6">
      <button
        pButton
        type="button"
        label="{{ 'ABOUT.CLOSE' | translate }}"
        class="p-button-outlined"
        style="width: 110px"
        (click)="closeViewContract()"
      ></button>
    </div>
    <div class="col-6 col-sm-6 d-flex justify-content-end">
      <button
        class="p-button"
        pButton
        type="button"
        label="{{ 'MODULES.CONTRACTS.DUPLICATE_CONTRACT' | translate }}"
        (click)="openDuplicateContrcat()"
      ></button>
    </div>
  </div>
</div>

<p-dialog
  header="{{ 'MODULES.CONTRACTS.ADD_METRICS' | translate }}"
  [(visible)]="isPopupVisible"
  *ngIf="isPopupVisible"
  [modal]="true"
  [draggable]="false"
  [maximizable]="true"
  [style]="{ width: '98vw' }"
  styleClass="custom-p-dialog"
>
  <app-choose-catalog
    [contractId]="viewData?.id"
    [save]="true"
    (closeClicked)="isPopupVisible = $event"
  >
  </app-choose-catalog>
</p-dialog>
