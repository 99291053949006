import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  sessionTimeoutRightSidebar = new Subject<any>();

  // Menu data BehaviorSubject and observable
  menuDataSubject = new BehaviorSubject<any[]>([]);

  menuData$ = this.menuDataSubject.asObservable();

  // Filter options BehaviorSubject and observable
  filterOptionsSubject = new BehaviorSubject<string[]>([]); // Store filter options as strings

  filterOptions$ = this.filterOptionsSubject.asObservable();

  setMenuData(data: any[]): void {
    this.menuDataSubject.next(data);
  }

  setFilterOptions(filters: string[]): void {
    this.filterOptionsSubject.next(filters); // Update filter options
  }
}
