import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CredentialsService } from '../services/credentials.service';
import { AuthService } from '@app/authentication/services/auth.service';

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private credentialsService: CredentialsService | undefined;

  private authService!: AuthService;

  constructor(
    private router: Router,
    private injector: Injector,
    private messageService: MessageService,
    private translate: TranslateService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = request;
    const token = this.injector.get(CredentialsService).authToken;
    const authService = this.injector.get(AuthService);

    if (token != null) {
      authReq = authService.addTokenHeader(request, token);
    }

    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.errorHandler(error, authReq, next)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: any, request: any, next: any): Observable<HttpEvent<any>> {
    this.credentialsService = this.injector.get(CredentialsService);
    this.authService = this.injector.get(AuthService);
    let errorMessage!: '';

    if (response instanceof HttpErrorResponse) {
      if (response?.error && response?.error?.errorCode === 'ValidationError') {
        errorMessage = response?.error.validationErrors;
      } else {
        const isErrFromRefreshToken = response?.url?.includes('/refreshToken');

        switch (response?.status) {
          case 401:
            if (!request.url.includes('/users/authenticate')) {
              return this.authService.refreshToken(request, next);
            }
            break;
          case 403:
            if (!isErrFromRefreshToken) {
              this.router.navigate(['/forbidden']);
              break;
            }
            break;
          case 404:
            this.router.navigate(['/not-found']);
            break;
          default:
            errorMessage = this.translate.instant('AUTH.DEFAULT_ERROR_MSG');
            break;
        }
      }
    }

    // eslint-disable-next-line no-prototype-builtins
    if (request && request.body?.hasOwnProperty('currentPassword') && request.body?.hasOwnProperty('newPassword')) {
      throw errorMessage;
    } else {
      if (errorMessage && response?.error?.errorCode !== 'ValidationError') {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('AUTH.DEFAULT_ERROR_SUMMARY'),
          detail: errorMessage,
        });
      }
      throw errorMessage;
    }
  }
}
