<h5 class="header-color py-3 px-4" translate>
  MODULES.CREATE_METRIC.VERSION_DETAILS
</h5>
<p-divider styleClass="my-0"></p-divider>

<form [formGroup]="createVersionForm">
  <div class="mb-2 px-4">
    <div class="row mt-2">
      <!-- version name -->
      <div class="col-4">
        <label class="fw-bold mb-2" for="versionName">{{
          'MODULES.CREATE_METRIC.VERSION_NAME' | translate
        }}</label>
        <span class="w-100 my-2">
          <input
            class="w-100 height-40 p-inputtext-sm"
            type="text"
            placeholder="{{ 'MODULES.CREATE_METRIC.VERSION_NAME' | translate }}"
            pInputText
            formControlName="versionName"
          />
        </span>
        <div *ngIf="createVersionForm.get('versionName')?.touched">
          <small
            *ngIf="createVersionForm.get('versionName')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>

          <small
            *ngIf="createVersionForm.get('versionName')?.errors?.['maxlength']"
            class="text-danger"
          >
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 20}' }}
          </small>
        </div>
      </div>

      <!-- target -->
      <div class="col-4">
        <label class="fw-bold mb-2" for="target">{{
          'MODULES.VIEW_METRICS.TARGET' | translate
        }}</label>
        <span class="w-100 my-2">
          <p-inputNumber
            mode="decimal"
            [minFractionDigits]="1"
            formControlName="target"
            placeholder="{{ 'MODULES.VIEW_METRICS.TARGET' | translate }}"
            (onBlur)="getTagetValue()"
          ></p-inputNumber>
        </span>
        <div *ngIf="createVersionForm.get('target')?.touched">
          <small
            *ngIf="createVersionForm.get('target')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- expected -->
      <div class="col-4">
        <label class="fw-bold mb-2" for="expected">{{
          'MODULES.VIEW_METRICS.EXPECTED' | translate
        }}</label>
        <span class="w-100 my-2">
          <p-inputNumber
            mode="decimal"
            [minFractionDigits]="1"
            placeholder="{{ 'MODULES.VIEW_METRICS.EXPECTED' | translate }}"
            formControlName="expected"
          ></p-inputNumber>
        </span>
        <div *ngIf="createVersionForm.get('expected')?.touched">
          <small
            *ngIf="createVersionForm.get('expected')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- start date -->
      <div class="col-4">
        <label class="fw-bold mb-2" for="startDate">{{
          'MODULES.VIEW_METRICS.START_DATE' | translate
        }}</label>
        <span class="w-100 my-2">
          <p-calendar
            styleClass="w-100 height-40"
            placeholder="{{ 'MODULES.VIEW_METRICS.START_DATE' | translate }}"
            formControlName="startDate"
            [showIcon]="true"
            dateFormat="dd-mm-yy"
            [minDate]="minStartDate"
            (onSelect)="onSelectStartDate($event)"
            [readonlyInput]="true"
            appendTo="body"
          >
          </p-calendar>
        </span>
        <div *ngIf="createVersionForm.get('startDate')?.touched">
          <small
            *ngIf="createVersionForm.get('startDate')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- end date -->
      <div class="col-4">
        <label class="fw-bold mb-2" for="endDate">{{
          'MODULES.VIEW_METRICS.END_DATE' | translate
        }}</label>
        <span class="w-100 my-2">
          <p-calendar
            styleClass="w-100 height-40"
            placeholder="{{ 'MODULES.VIEW_METRICS.END_DATE' | translate }}"
            formControlName="endDate"
            [showIcon]="true"
            [minDate]="minEndDate"
            [maxDate]="maxEndDate"
            dateFormat="dd-mm-yy"
            [readonlyInput]="true"
            [defaultDate]="minEndDate"
            appendTo="body"
          >
          </p-calendar>
        </span>
        <div *ngIf="createVersionForm.get('endDate')?.touched">
          <small
            *ngIf="createVersionForm.get('endDate')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
      </div>

      <!-- version statement -->
      <div class="col-4">
        <label class="fw-bold mb-2" for="metricVersionStatement">{{
          'MODULES.CONTRACTS.NOTES' | translate
        }}</label>
        <span class="w-100 my-2">
          <textarea
            class="w-100 height-40"
            pInputTextarea
            placeholder="{{ 'MODULES.CONTRACTS.NOTES' | translate }}"
            formControlName="metricVersionStatement"
          ></textarea>
        </span>
        <div *ngIf="createVersionForm.get('metricVersionStatement')?.touched">
          <small
            *ngIf="createVersionForm.get('metricVersionStatement')?.errors?.['maxlength']"
            class="text-danger"
          >
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
          </small>
        </div>
      </div>
    </div>
  </div>

  <!--UDF Version-->
  <h5 translate class="mt-4 px-4" *ngIf="UDFVersionList && UDFVersionList.length">MODULES.VIEW_METRICS.UDF_VERSION</h5>
  <p-divider styleClass="mb-0" *ngIf="UDFVersionList && UDFVersionList.length"></p-divider>

  <div class="px-4">
    <ng-container *ngIf="UDFVersionList?.length">
      <app-udf
        [udfInfo]="UDFVersionList"
        (formDataChanged)="onFormDataChanged($event)"
      ></app-udf>
    </ng-container>

    <ng-container *ngIf="UDFVersionList && !UDFVersionList.length">
      <div class="row mt-2">
        <div class="col-4 mb-1" *ngFor="let item of [].constructor(6)">
          <p-skeleton></p-skeleton>
        </div>
      </div>
    </ng-container>

    <!-- api error msg block -->
    <ng-container *ngIf="errorMsgList?.length">
      <div class="col-12 error-block">
        <li *ngFor="let msg of errorMsgList">{{ msg }}</li>
      </div>
    </ng-container>

    <!-- btn section -->
    <div class="row mt-2">
      <div class="col-4 col-sm-4">
        <button
          pButton
          type="button"
          label="{{ 'MODULES.DISTRIBUTIONS.CANCEL' | translate }}"
          class="p-button-outlined"
          style="width: 110px"
          (click)="resetForm()"
        ></button>
      </div>
      <div class="col-4 col-sm-4 text-center">
        <button
          pButton
          type="submit"
          label="{{ 'MODULES.DISTRIBUTIONS.SAVE' | translate }}"
          [disabled]="!metricPermission.hasEditMetricVersion()"
          class="p-button"
          style="width: 110px"
          (click)="saveNewVersion(viewData.id)"
        ></button>
      </div>
      <div class="col-4 col-sm-4 text-right">
        <button
          pButton
          type="submit"
          label="{{ 'MODULES.CREATE_METRIC.PUBLISH' | translate }}"
          [disabled]="!metricPermission.hasPublishMetricVersion()"
          class="p-button"
          style="width: 110px"
          (click)="cofirmPublish($event)"
        ></button>
      </div>
    </div>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
