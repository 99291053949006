import { Injectable } from '@angular/core';

import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';

import { LoginResponse } from '../../authentication/interfaces/login.element';

const credentialsKey = 'credentials';
const permissionsKey = 'permissions';
const userIdKey = 'userId';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  permissions: any | null = null;

  userId: any | null = null;

  private credentials: LoginResponse | null = null;

  private credentialsRefreshedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public credentialsRefreshed$: Observable<boolean> = this.credentialsRefreshedSubject.asObservable();

  constructor() {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    const savedPermissions = sessionStorage.getItem(permissionsKey) || localStorage.getItem(permissionsKey);
    const savedUserId = sessionStorage.getItem(userIdKey) || localStorage.getItem(userIdKey);

    if (savedCredentials) {
      this.credentials = JSON.parse(savedCredentials);
    }

    if (savedPermissions) {
      this.permissions = JSON.parse(savedPermissions);
    }

    if (savedUserId) {
      this.userId = JSON.parse(savedUserId);
    }
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get user(): any | null {
    if (this.credentials) {
      const { family_name, given_name, email, email_verified, name, locale, preffered_username } = this.decryptedToken;

      return {
        family_name,
        given_name,
        email,
        email_verified,
        name,
        locale,
        preffered_username,
      };
    }

    return null;
  }

  get authToken(): string | undefined {
    return this.credentials?.access_token;
  }

  get decryptedToken(): any {
    try {
      if (this.credentials) {
        return jwt_decode(this.credentials?.access_token);
      }

      return null;
    } catch (Error) {
      return null;
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: LoginResponse, remember?: boolean): void {
    this.credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;

      storage.setItem(credentialsKey, JSON.stringify(credentials));
      this.credentialsRefreshedSubject.next(true);
    } else {
      // clear session
      sessionStorage.removeItem(credentialsKey);
      sessionStorage.clear();

      // clear local
      localStorage.removeItem(credentialsKey);
      localStorage.clear();
      this.credentialsRefreshedSubject.next(false);
    }
  }

  resetCredentials(): void {
    sessionStorage.removeItem(credentialsKey);
    localStorage.removeItem(credentialsKey);
  }

  setPermissions(permissions?: any, remember?: boolean): void {
    this.permissions = permissions || null;

    if (permissions) {
      const storage = remember ? localStorage : sessionStorage;

      storage.setItem(permissionsKey, JSON.stringify(permissions));
    } else {
      sessionStorage.removeItem(permissionsKey);
      localStorage.removeItem(permissionsKey);
    }
  }

  setUserId(userId?: any, remember?: boolean): void {
    this.userId = userId || null;

    if (userId) {
      const storage = remember ? localStorage : sessionStorage;

      storage.setItem(userIdKey, JSON.stringify(userId));
    } else {
      sessionStorage.removeItem(userIdKey);
      localStorage.removeItem(userIdKey);
    }
  }
}
