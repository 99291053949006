import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { environment } from '@env/environment';

const routes = {
  globalSearch: `${environment.SLMUrl}/globalsearch`,
};

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  filterSubject = new BehaviorSubject<any[]>([]); // Subject to store filters

  public filters$ = this.filterSubject.asObservable(); // Expose observable to subscribe to

  constructor(private httpClient: HttpClient) {}

  getGlobalSeach(request: any): any {
    return this.httpClient.post(`${routes.globalSearch}`, request);
  }

  setFilters(filterConditions: any[]): void {
    this.filterSubject.next(filterConditions); // Update filter conditions
  }

  clearFilters(): void {
    this.filterSubject.next([]); // Clear filters
  }
}
