import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

const routes = {
  complianceHeaders: `${environment.SLMUrl}/reports/header/compliance`,
  incidentHeaders: `${environment.SLMUrl}/reports/header/insights_incidents`,
  rawKPIAgg: `${environment.SLMUrl}/reports/header/kpi_raw_aggregator`,
  rawKPI: `${environment.SLMUrl}/reports/header/kpi_raw`,
  reportHeaders: `${environment.SLMUrl}/reports`,
  resetPassword: `${environment.SLMUrl}/users`,
};

@Injectable({
  providedIn: 'root',
})
export class HeadersService {
  constructor(private httpClient: HttpClient) {}

  getComplianceHeaders(months = 6): any {
    return this.httpClient.get(`${routes.complianceHeaders}?numberOfMonth=${months}`);
  }

  getIncidentHeaders(months = 6): any {
    return this.httpClient.get(`${routes.incidentHeaders}?numberOfMonth=${months}`);
  }

  getRawKPIAggHeaders(): any {
    return this.httpClient.get(`${routes.rawKPIAgg}`);
  }

  getRawKPIHeaders(): any {
    return this.httpClient.get(`${routes.rawKPI}`);
  }

  getReportHeaders(id: number, params: any = {}): any {
    return this.httpClient.get(`${routes.reportHeaders}/${id}/header`, { params });
  }

  updatePassword(
    payload: {
      currentPassword: string;
      newPassword: string;
    },
    userId: number
  ): any {
    return this.httpClient.put(`${routes.resetPassword}/${userId}/reset-password`, payload);
  }
}
