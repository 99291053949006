import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// PrimeNG
import { TranslateModule } from '@ngx-translate/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HasPermissionDirective } from './components/main-menu/has-permission.directive';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { SearchComponent } from './components/search/search.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { LayoutComponent } from './layout.component';
import { I18nModule } from '@app/i18n';
// Shared module
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [
    LayoutComponent,
    TopbarComponent,
    BreadcrumbComponent,
    LoaderComponent,
    MainMenuComponent,
    HasPermissionDirective,
    SearchComponent,
    ChangePasswordComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    InputTextModule,
    SidebarModule,
    TooltipModule,
    ProgressSpinnerModule,
    BlockUIModule,
    BadgeModule,
    BreadcrumbModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    SharedModule,
    I18nModule,
    TranslateModule,
    MessageModule,
    ConfirmPopupModule,
    ToastModule,
    DividerModule,
    AvatarModule,
    MenuModule,
    DialogModule,
    PasswordModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  exports: [LayoutComponent, LoaderComponent],
  providers: [MessageService, ConfirmationService],
})
export class LayoutModule {}
