
<div class="right-sidebar-header">
    <h5 class="px-4 fw-bold d-inline">{{'CHANGE_PASSWORD_DIALOG.CHANGE_PASSWORD' | translate}}</h5>
    <p-divider styleClass="mb-0"></p-divider>
</div>

<form [formGroup]="newPasswordform">
  <div class="px-4">
    <div class="row mt-2">
      <!-- Current Password -->
      <div class="col-12">
        <label class="fw-bold mb-2 required" for="name">
          {{ 'CHANGE_PASSWORD_DIALOG.CURRENT_PASSWORD' | translate }}
        </label>

        <span class="w-100 my-1">
          <p-password styleClass="w-100 height-40" inputStyleClass="w-100"
            placeholder="{{ 'CHANGE_PASSWORD_DIALOG.CURRENT_PASSWORD_PLACEHOLDER' | translate }}"
            formControlName="currentPassword" [toggleMask]="true" [feedback]="false">
          </p-password>
        </span>

        <div *ngIf="newPasswordform.get('currentPassword')?.touched">
          <small class="text-danger" *ngIf="newPasswordform.get('currentPassword')?.errors?.['required']">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>

      </div>

      <!-- New Password -->
      <div class="col-12">
        <label class="fw-bold mb-2 required" for="name">
          {{ 'CHANGE_PASSWORD_DIALOG.NEW_PASSWORD' | translate }}
        </label>

        <span class="w-100 my-1">
          <p-password styleClass="w-100 height-40" inputStyleClass="w-100"
            placeholder="{{ 'CHANGE_PASSWORD_DIALOG.NEW_PASSWORD_PLACEHOLDER' | translate }}"
            formControlName="newPassword" [toggleMask]="true" [feedback]="true"
            promptLabel="{{ 'CHANGE_PASSWORD_DIALOG.PROMPT_LABEL' | translate }}"
            weakLabel="{{ 'CHANGE_PASSWORD_DIALOG.WEAK_LABEL' | translate }}"
            mediumLabel="{{ 'CHANGE_PASSWORD_DIALOG.MEDIUM_LABEL' | translate }}"
            strongLabel="{{ 'CHANGE_PASSWORD_DIALOG.STRONG_LABEL' | translate }}">

            <!-- password suggestion container -->
            <ng-template pTemplate="footer">
              <p [style.color]="!newPasswordform.get('newPassword')?.value || newPasswordform.get('newPassword')?.errors?.['maxlength'] || newPasswordform.get('newPassword')?.errors?.['minlength'] ? '#a64b38' : '#87b140'">
                <i class="pi pi-check icon-font"
                  [style.color]="!newPasswordform.get('newPassword')?.value || newPasswordform.get('newPassword')?.errors?.['maxlength'] || newPasswordform.get('newPassword')?.errors?.['minlength'] ? '#a64b38' : '#87b140'"
                  *ngIf="!!newPasswordform.get('newPassword')?.value && !newPasswordform.get('newPassword')?.errors?.['maxlength'] && !newPasswordform.get('newPassword')?.errors?.['minlength']">
                </i>
                <i class="pi pi-times icon-font"
                  [style.color]="!newPasswordform.get('newPassword')?.value || newPasswordform.get('newPassword')?.errors?.['maxlength'] || newPasswordform.get('newPassword')?.errors?.['minlength'] ? '#a64b38' : '#87b140'"
                  *ngIf="!newPasswordform.get('newPassword')?.value || newPasswordform.get('newPassword')?.errors?.['maxlength'] || newPasswordform.get('newPassword')?.errors?.['minlength']">
                </i>
                {{'CHANGE_PASSWORD_DIALOG.PASSWORD_LONG_MSG' | translate}}
              </p>
              <p>{{'CHANGE_PASSWORD_DIALOG.PASSWORD_FOLLOW_MSG' | translate}} :</p>

              <ng-container [ngTemplateOutlet]="msgBox"
                [ngTemplateOutletContext]="{msg: 'CHANGE_PASSWORD_DIALOG.CAPITAL_LETTERS', isValid: isValidUpperCase}">
              </ng-container>
              <ng-container [ngTemplateOutlet]="msgBox"
                [ngTemplateOutletContext]="{msg: 'CHANGE_PASSWORD_DIALOG.SMALL_LETTERS', isValid: isValidLowerCase}">
              </ng-container>
              <ng-container [ngTemplateOutlet]="msgBox"
                [ngTemplateOutletContext]="{msg: 'CHANGE_PASSWORD_DIALOG.NUMBER_MSG', isValid: isValidNumberCase}">
              </ng-container>
              <ng-container [ngTemplateOutlet]="msgBox"
                [ngTemplateOutletContext]="{msg: 'CHANGE_PASSWORD_DIALOG.SPECIAL_CHAR', isValid: isValidSpecialCharCase}">
              </ng-container>
            </ng-template>

          </p-password>
        </span>

        <div *ngIf="newPasswordform.get('newPassword')?.touched">
          <small class="text-danger" *ngIf="newPasswordform.get('newPassword')?.errors?.['required']">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
          <small class="text-danger" *ngIf="minCurrectLength < 3 && (!isValidUpperCase || !isValidLowerCase || !isValidNumberCase || !isValidSpecialCharCase) ||
            (newPasswordform.get('newPassword')?.errors?.['minlength'] || newPasswordform.get('newPassword')?.errors?.['maxlength'])">
            {{ 'CHANGE_PASSWORD_DIALOG.FUllFILL_MSG' | translate }}
          </small>
        </div>
      </div>

      <!-- Confirm Password -->
      <div class="col-12">
        <label class="fw-bold mb-2 required" for="name">
          {{ 'CHANGE_PASSWORD_DIALOG.CONFIRM_PASSWORD' | translate }}
        </label>

        <span class="w-100 my-1">
          <p-password styleClass="w-100 height-40" inputStyleClass="w-100"
            placeholder="{{ 'CHANGE_PASSWORD_DIALOG.CONFIRM_PASSWORD_PLACEHOLDER' | translate }}"
            formControlName="confirmPassword" [toggleMask]="true" [feedback]="false">
          </p-password>
        </span>

        <div *ngIf="newPasswordform.get('confirmPassword')?.touched">
          <small class="text-danger" *ngIf="newPasswordform.get('confirmPassword')?.errors?.['required']">
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
          <small class="text-danger" *ngIf="newPasswordform.get('confirmPassword')?.errors?.['confirmedValidator']">
            {{'CHANGE_PASSWORD_DIALOG.PASSWORD_MATCH' | translate }}
          </small>
        </div>
      </div>
    </div>
  </div>

  <div class="px-4">
    <!-- Note -->
    <div class="font-12">
      <b>{{ 'CHANGE_PASSWORD_DIALOG.LOGIN_REDIRECTION_NOTE' | translate }}</b>
    </div>

    <!-- api error msg block -->
    <ng-container *ngIf="errorMsgList?.length">
      <div class="error-block">
        <li *ngFor="let msg of errorMsgList">{{ msg }}</li>
      </div>
    </ng-container>

    <!-- btn section -->
    <div class="row d-flex justify-content-between mt-2">
      <div class="col-4">
        <button pButton class="p-button secondary-btn" style="width: 110px" type="submit"
          label="{{ 'MODULES.DISTRIBUTIONS.CANCEL' | translate }}" (click)="cancel()">
        </button>
      </div>

      <div class="col-4 text-right">
        <button pButton class="p-button" type="submit" style="width: 150px"
          label="{{ 'CHANGE_PASSWORD_DIALOG.UPDATE_PASSWORD' | translate }}"
          [disabled]="disableResetBtn" (click)="resetPassword()">
        </button>
      </div>
    </div>

    <!-- Password suggestions msg -->
    <ng-template #msgBox let-msg='msg' let-isValid="isValid">
      <p [style.color]="!isValid ? '#a64b38' : '#87b140'">
        <i class="pi pi-check icon-font" [style.color]="!isValid ? '#a64b38' : '#87b140'"
          *ngIf="isValid"></i>
        <i class="pi pi-times icon-font" [style.color]="!isValid ? '#a64b38' : '#87b140'"
          *ngIf="!isValid"></i>
        {{ msg | translate }}
      </p>
    </ng-template>
  </div>
</form>