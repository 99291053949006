import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Output, EventEmitter, OnInit, ElementRef, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, filter, map } from 'rxjs/operators';

import { AutocompleteItem, AutocompleteGroup, AutocompleteConfig } from '@app/layout/components/search/search';
import { LayoutService } from '@app/layout/services/layout.service';
import { SearchService } from '@app/layout/services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10px)' }), // Start slightly above and hidden
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })), // Slide down and fade in
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(-10px)' })), // Slide up and fade out
      ]),
    ]),
  ],
})
export class SearchComponent implements OnInit, OnDestroy {
  @Output() itemSelected = new EventEmitter<AutocompleteItem>();

  @Output() searchChange = new EventEmitter<string>();

  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;

  items: AutocompleteGroup[] = [];

  filteredItems: AutocompleteGroup[] = [];

  activeFilters: string[] = [];

  config: AutocompleteConfig = { minLength: 4, debounceTime: 400 };

  filterOptions: string[] = [];

  searchControl = new FormControl('');

  filterForm: FormGroup;

  isExpanded = false;

  showSearchIcon = true;

  isSelectAllChecked = false; // Tracks if "Select All" is fully checked

  isIndeterminate = false; // Tracks if "Select All" is in the indeterminate state

  parentMapping: Record<string, string[]> = {};

  private clickListener: (() => void) | undefined;

  constructor(
    private router: Router,
    private el: ElementRef,
    private layoutService: LayoutService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private searchService: SearchService
  ) {
    this.filterForm = this.fb.group({
      filters: this.fb.array([]),
    });
  }

  get filtersArray(): FormArray<FormControl> {
    return this.filterForm.get('filters') as FormArray<FormControl>;
  }

  get isAnyFilterSelected(): boolean {
    return this.filtersArray.controls.some(control => control.value); // Check if any filter is selected
  }

  ngOnInit(): void {
    //  this.setupSearch();  // this will intialze search after 3 character
    this.filteredItems = []; // Hide results initially to dispaly search box
    this.translate.onLangChange.subscribe(() => {
      // Waiting for translations to load and then initialize filters
      this.initializeFiltersWithTranslation();
    });
    this.layoutService.menuData$
      .pipe(
        map(menuData => {
          this.parentMapping = this.createParentChildMapping(menuData); // Generate parent-child mapping

          return menuData;
        }),
        switchMap(menuData => this.transformMenuDataToFilterOptions(menuData)) // Apply exclusion and translation logic
      )
      .subscribe(filterOptions => {
        this.filterOptions = filterOptions; // Already filtered and unique values
        this.initializeFilters(); // Creating controls based on the filtered options
        this.applyFilters(); // Apply initial filters if any
      });

    // Control the search icon display based on input
    this.searchControl.valueChanges.subscribe((value: any) => {
      this.showSearchIcon = !value;
      if (!value || value.length < this.config.minLength) {
        this.filteredItems = []; // Clear filtered items if input is invalid or too short
      }
    });
  }

  /**
   * Filters items in a hierarchical structure based on the search query.
   *
   * @param query -  The search string to filter the items.
   * @returns  An Observable emitting an array of AutocompleteGroup objects
   *          containing only the groups and items that match the query.
   */
  filterItems(query: string): Observable<AutocompleteGroup[]> {
    const filteredItems = this.items
      .map(group => {
        /**
         * Filter child items in the current group where the item's label
         * includes the query string (case-insensitive).
         */
        const filteredChildren = group.items.filter(item => item.label.toLowerCase().includes(query.toLowerCase()));

        /**
         * Return a new group object containing only the filtered child items.
         * Spread operator ensures the original group is not mutated.
         */
        return { ...group, items: filteredChildren };
      })
      /**
       * Wrap the filtered groups in an Observable using the RxJS `of` operator.
       */
      .filter(group => group.items.length > 0);

    return of(filteredItems);
  }

  toggleSelectAll(): void {
    this.isSelectAllChecked = !this.isSelectAllChecked; // Toggle "Select All" state
    this.isIndeterminate = false; // Reset indeterminate state

    // Set all individual checkboxes based on "Select All" state
    this.filtersArray.controls.forEach(control => control.setValue(this.isSelectAllChecked));

    // Update active filters
    this.updateActiveFilters();
  }

  /**
   * @param
   *
   */

  /**
   * Updates the active filters based on the states of individual checkboxes.
   * This method also manages the "Select All" and "Indeterminate" checkbox states
   * and triggers the application of the selected filters.
   */
  updateActiveFilters(): void {
    // Update active filters based on individual checkbox states
    this.activeFilters = this.filtersArray.controls
      .map((control, index) => (control.value ? this.filterOptions[index] : null))
      .filter(name => name !== null) as string[];

    // Check if all, none, or some checkboxes are selected
    const totalSelected = this.filtersArray.controls.filter(control => control.value).length;
    const totalCheckboxes = this.filtersArray.length;

    this.isSelectAllChecked = totalSelected === totalCheckboxes;
    this.isIndeterminate = totalSelected > 0 && totalSelected < totalCheckboxes;

    if (totalSelected === totalCheckboxes) {
      // All checkboxes are selected
      this.isSelectAllChecked = true;
      this.isIndeterminate = false;
    } else if (totalSelected > 0) {
      // Some checkboxes are selected
      this.isSelectAllChecked = false;
      this.isIndeterminate = true;
    } else {
      // No checkboxes are selected
      this.isSelectAllChecked = false;
      this.isIndeterminate = false;
    }

    // Apply filters if necessary
    this.applyFilters();
  }

  /**
   * Filters the hierarchical data (`items`) based on active filters.
   * If no filters are active, all items are displayed. Otherwise,
   * items and groups are filtered to match the active filters or their mappings.
   */
  applyFilters(): void {
    if (this.activeFilters.length === 0) {
      this.filteredItems = [...this.items]; // Show all items if no filters
    } else {
      const activeSet = new Set<string>(
        this.activeFilters.flatMap(activeFilter => this.parentMapping[activeFilter] || [activeFilter])
      );

      this.filteredItems = this.items
        .filter(group => activeSet.has(group.name) || group.items.some(item => activeSet.has(item.label)))
        .map(group => ({
          ...group,
          items: group.items.filter(item => activeSet.has(item.label)),
        }))
        .filter(group => group.items.length > 0);
    }
  }

  /**
   * Fetches and maps search data from the server into a hierarchical format for autocomplete.
   *
   * @returns An Observable emitting an array of AutocompleteGroup objects. Each group
   *          contains a name and items derived from the API response.
   */
  getMappedData(): Observable<AutocompleteGroup[]> {
    const payload: any = {
      searchText: this.searchControl.value || '',
      searchEntities: this.getActiveFilters(),
    };

    return this.searchService.getGlobalSeach(payload).pipe(
      map((response: any) => {
        if (response && response.results) {
          const rawData = response.results;

          return Object.keys(rawData).map(apiKey => {
            // Find the corresponding menu item for the API key
            const menuData = this.layoutService.menuDataSubject.getValue();
            const label = this.getLabelFromApiKey(apiKey, menuData);

            return {
              name: label || apiKey, // Use label if available, fallback to apiKey
              items: rawData[apiKey].map((item: any) => ({
                label: item.name,
                id: item.id,
                group: apiKey,
              })),
            };
          });
        }

        return [];
      })
    );
  }

  onSelect(item: AutocompleteItem): void {
    this.itemSelected.emit(item); // Emit the selected item
    this.searchControl.setValue(''); // Set the search input value
    this.isExpanded = false; // Close the dropdown

    const selectedGroup = item.group; // API key (e.g., CONTRACT)

    if (!selectedGroup) {
      return;
    }

    // Step 1: Retrieve the latest menu data
    const menuData = this.layoutService.menuDataSubject.getValue();

    // Step 2: Find the parent containing the child with the matching `apiKey`
    const parent = menuData
      .flatMap(section => section.items)
      .find(parentItem => parentItem.submenu?.some((submenuItem: any) => submenuItem.apiKey === selectedGroup));

    if (!parent) {
      return;
    }

    // Step 3: Find the child menu item with the matching `apiKey`
    const child = parent.submenu?.find((submenuItem: any) => submenuItem.apiKey === selectedGroup);

    if (!child || !child.routerLink) {
      return;
    }

    // Step 4: Prepare filter conditions to pass to the target component
    const filterConditions = [
      {
        fieldName: item.label, // Replace with actual fieldName
        // filterType: 'number', // Adjust based on the type of the filter
        conditions: [
          {
            type: 'equals',
            start: item.id, // Use the selected item's ID as the value
          },
        ],
      },
    ];

    // Step 5: Navigate to the target component with the filter conditions
    this.searchService.setFilters(filterConditions);
    const { routerLink } = child;

    this.router.navigate(routerLink);
    setTimeout(() => {
      this.isExpanded = false;
    }, 200); // Optional delay to ensure dropdown closes smoothly
  }

  clearSearch(): void {
    this.searchControl.setValue('');
    this.showSearchIcon = true;
    this.filteredItems = [];
    this.isExpanded = false;
  }

  expandSearch(): void {
    const searchValue = this.searchControl.value || '';

    if (searchValue.length >= this.config.minLength || searchValue === '') {
      this.openDropdown();
    }
  }

  clearAllFilter(): void {
    this.filtersArray.controls.forEach(control => control.setValue(false)); // Uncheck all
    this.isSelectAllChecked = false;
    this.isIndeterminate = false;
    this.updateActiveFilters();
  }

  hasNoResults(): boolean {
    const searchValue = this.searchControl.value || ''; // Use an empty string if `value` is null

    return searchValue.length >= this.config.minLength && this.filteredItems.every(group => group.items.length === 0);
  }

  openDropdown(): void {
    this.isExpanded = true;
  }

  closeDropdown(): void {
    this.isExpanded = false;
  }

  /**
   * Creates a mapping between parent menu labels and their respective child API keys.
   *
   * @param menuData - The array of menu sections containing parent and child items.
   * @returns A record object where the keys are parent menu labels and the values are arrays of child API keys.
   */
  private createParentChildMapping(menuData: any[]): Record<string, string[]> {
    const mapping: Record<string, string[]> = {};

    menuData.forEach(section => {
      section.items.forEach((parent: { submenu: any[]; label: string | number }) => {
        if (parent.submenu && parent.submenu.length > 0) {
          mapping[parent.label] = parent.submenu.filter((child: any) => child.apiKey).map((child: any) => child.apiKey);
        }
      });
    });

    return mapping;
  }

  private setupSearch(): void {
    this.searchControl.valueChanges
      .pipe(
        filter((value): value is string => value !== null), // Exclude null values
        debounceTime(this.config.debounceTime), // Debounce to avoid excessive API calls
        distinctUntilChanged(), // Only trigger if value changes
        filter((value: string) => value.length > 3), // Only proceed if input length is >= 3
        switchMap(() => this.getMappedData()) // Dynamically build the payload and fetch data
      )
      .subscribe(mappedData => {
        this.filteredItems = mappedData; // Update UI with fetched data
        this.isExpanded = this.filteredItems.length > 0 || this.hasNoResults(); // Expand dropdown if there are results
      });

    // Listen to filter changes and trigger search
    this.filtersArray.valueChanges
      .pipe(
        debounceTime(100) // Optional: Debounce filter changes
      )
      .subscribe(() => {
        this.getMappedData().subscribe(mappedData => {
          this.filteredItems = mappedData;
        });
      });
  }

  private transformMenuDataToFilterOptions(menuData: any[]): Observable<string[]> {
    const excludeLabels = ['BREADCRUMBS.HOME', 'VISUAL DESIGNER'];
    const translatedLabels = menuData.flatMap(
      menuGroup =>
        menuGroup.items
          .filter((item: any) => !excludeLabels.includes(item.label))
          .map((item: any) => this.translate.instant(item.label))
          .filter((label: { trim: () => { (): any; new (): any; length: number } }) => label && label.trim().length > 0) // Ensure valid labels
    );

    return of([...new Set(translatedLabels)]); // Ensure unique labels
  }

  private initializeFiltersWithTranslation(): void {
    this.layoutService.menuData$
      .pipe(
        map(menuData => this.createParentChildMapping(menuData)),
        switchMap(() => this.transformMenuDataToFilterOptions(this.layoutService.menuDataSubject.getValue())) // Fetch translated filter options
      )
      .subscribe(filterOptions => {
        this.filterOptions = filterOptions;

        // Create FormArray controls for the translated options
        const controlArray = this.filtersArray;

        controlArray.clear();
        this.filterOptions.forEach(() => controlArray.push(this.fb.control(false)));
      });
  }

  private initializeFilters(): void {
    const controlArray = this.filterForm.get('filters') as FormArray;

    controlArray.clear();

    // Use pre-processed filterOptions that already exclude unwanted labels
    this.filterOptions.forEach(() => controlArray.push(this.fb.control(false)));
  }

  // Helper method to map `apiKey` to its corresponding label
  private getLabelFromApiKey(apiKey: string, menuData: any[]): string | undefined {
    return menuData
      .flatMap(section => section.items)
      .flatMap(item => item.submenu || [])
      .find((child: any) => child.apiKey === apiKey)?.label;
  }

  private getActiveFilters(): string[] {
    const filterArray = this.filtersArray.controls; // Get all form controls for filters

    // Step 1: Extract selected filters
    const selectedFilters = filterArray
      .map((control, index) => (control.value ? this.filterOptions[index] : null))
      .filter((filterOption): filterOption is string => filterOption !== null); // Remove null values

    // Step 2: Access menuData and include apiKeys for parent or child selections
    const menuData = this.layoutService.menuDataSubject.getValue(); // Access menu data from BehaviorSubject

    if (!menuData || menuData.length === 0) {
      return [];
    }

    const mappedApiKeys = selectedFilters
      .flatMap(selectedFilter => {
        return menuData.flatMap(section =>
          section.items.flatMap((item: { label: string; submenu: any[] }) => {
            // If parent (e.g., "SLM") is selected, include all its children apiKeys
            if (item.label === selectedFilter && item.submenu?.length > 0) {
              return item.submenu
                .filter(submenuItem => submenuItem.apiKey) // Ensure apiKey exists
                .map(submenuItem => submenuItem.apiKey); // Map all child apiKeys
            }

            // If a specific child is selected, include only its apiKey
            return (
              item.submenu
                ?.filter(submenuItem => submenuItem.label === selectedFilter && submenuItem.apiKey) // Match child label
                .map(submenuItem => submenuItem.apiKey) || []
            ); // Map the matching child's apiKey
          })
        );
      })
      .filter((apiKey): apiKey is string => !!apiKey); // Ensure no null/undefined values

    return mappedApiKeys;
  }

  ngOnDestroy(): void {
    if (this.clickListener) {
      this.clickListener();
    }
  }

  @HostListener('keydown.enter', ['$event'])
  disableEnterKey(event: KeyboardEvent): void {
    event.preventDefault();
  }

  @HostListener('focusin', ['$event'])
  onFocus(): void {
    this.expandSearch();
  }

  @HostListener('document:click', ['$event'])
  onGlobalClick(event: Event): void {
    const clickedInside = this.el.nativeElement.contains(event.target);

    if (clickedInside) {
      this.isExpanded = true; // Keep dropdown open
      //  this.searchInput.nativeElement.focus(); // Ensure input stays focused
    } else {
      this.isExpanded = false; // Collapse dropdown on external click
    }
  }
}
